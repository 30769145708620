import { resourceService } from "../../services";

const getDefaultState = () => {
  return {
    roles: [],
    componentFibers: [],
    componentTypes: [],
    componentCertifications: [],
    materialCertifications: [],
    materialColors: [],
    materialConstructions: [],
    materialDyeMethods: [],
    materialPrintMethods: [],
    materialPatterns: [],
    materialFinishings: [],
    productFinishings: [],
    productCategories: [],
    productTypes: [],
    productRecycling: [],
    stepTypes: [],
    locations: [],
    status: {},
    error: null,
  };
};

// initial state
const state = getDefaultState();

// actions
const actions = {
  listRoles({ commit }: any) {
    commit("waitResponse");
    resourceService.listRoles().then(
      (res: any) => {
        commit("roleSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listComponentFibers({ commit }: any) {
    commit("waitResponse");
    resourceService.listComponentFibers().then(
      (res: any) => {
        commit("componentFiberSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listComponentTypes({ commit }: any) {
    commit("waitResponse");
    resourceService.listComponentTypes().then(
      (res: any) => {
        commit("componentTypesSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listComponentCertifications({ commit }: any) {
    commit("waitResponse");
    resourceService.listComponentCertifications().then(
      (res: any) => {
        commit("componentCertificationsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listMaterialCertifications({ commit }: any) {
    commit("waitResponse");
    resourceService.listMaterialCertifications().then(
      (res: any) => {
        commit("materialCertificationsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listMaterialColors({ commit }: any) {
    commit("waitResponse");
    resourceService.listMaterialColors().then(
      (res: any) => {
        commit("materialColorsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listMaterialConstructions({ commit }: any) {
    commit("waitResponse");
    resourceService.listMaterialConstructions().then(
      (res: any) => {
        commit("materialConstructionsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listMaterialDyeMethods({ commit }: any) {
    commit("waitResponse");
    resourceService.listMaterialDyeMethods().then(
      (res: any) => {
        commit("materialDyeMethodsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listMaterialPrintMethods({ commit }: any) {
    commit("waitResponse");
    resourceService.listMaterialPrintMethods().then(
      (res: any) => {
        commit("materialPrintMethodsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listMaterialPatterns({ commit }: any) {
    commit("waitResponse");
    resourceService.listMaterialPatterns().then(
      (res: any) => {
        commit("materialPatternsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listMaterialFinishings({ commit }: any) {
    commit("waitResponse");
    resourceService.listMaterialFinishings().then(
      (res: any) => {
        commit("materialFinishingsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listProductFinishings({ commit }: any) {
    commit("waitResponse");
    resourceService.listProductFinishings().then(
      (res: any) => {
        commit("productFinishingsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listProductCategories({ commit }: any) {
    commit("waitResponse");
    resourceService.listProductCategories().then(
      (res: any) => {
        commit("productCategoriesSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listProductTypes({ commit }: any) {
    commit("waitResponse");
    resourceService.listProductTypes().then(
      (res: any) => {
        commit("productTypesSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listProductRecycling({ commit }: any) {
    commit("waitResponse");
    resourceService.listProductRecycling().then(
      (res: any) => {
        commit("productRecyclingSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listStepTypes({ commit }: any) {
    commit("waitResponse");
    resourceService.listStepTypes().then(
      (res: any) => {
        commit("stepTypesSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listStepTypesByCategoryName({ commit }: any, category: string) {
    commit("waitResponse");
    resourceService.listStepTypesByCategoryName(category).then(
      (res: any) => {
        commit("stepTypesSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  listLocations({ commit }: any) {
    commit("waitResponse");
    resourceService.listLocations().then(
      (res: any) => {
        commit("locationsSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
};

// getters
const getters = {
  getRoles(state: any) {
    return state.roles;
  },
  getComponentFibers(state: any) {
    return state.componentFibers;
  },
  getComponentTypes(state: any) {
    return state.componentTypes;
  },
  getComponentCertifications(state: any) {
    return state.componentCertifications;
  },
  getMaterialCertifications(state: any) {
    return state.materialCertifications;
  },
  getMaterialColors(state: any) {
    return state.materialColors;
  },
  getMaterialConstructions(state: any) {
    return state.materialConstructions;
  },
  getMaterialDyeMethods(state: any) {
    return state.materialDyeMethods;
  },
  getMaterialPrintMethods(state: any) {
    return state.materialPrintMethods;
  },
  getMaterialPatterns(state: any) {
    return state.materialPatterns;
  },
  getMaterialFinishings(state: any) {
    return state.materialFinishings;
  },
  getProductFinishings(state: any) {
    return state.productFinishings;
  },
  getProductCategories(state: any) {
    return state.productCategories;
  },
  getProductTypes(state: any) {
    return state.productTypes;
  },
  getProductRecycling(state: any) {
    return state.productRecycling;
  },
  getStepTypes(state: any) {
    return state.stepTypes;
  },
  getLocations(state: any) {
    return state.locations;
  },
};

// mutations
const mutations = {
  resetState(state: any) {
    Object.assign(state, getDefaultState());
  },
  waitResponse(state: any) {
    state.status = { isWaiting: true };
  },
  failure(state: any, error: any) {
    state.error = error;
    state.status = {};
  },
  roleSuccess(state: any, roles: any[]) {
    state.roles = roles;
    state.status = {};
  },
  componentFiberSuccess(state: any, data: any[]) {
    state.componentFibers = data;
    state.status = {};
  },
  componentTypesSuccess(state: any, data: any[]) {
    state.componentTypes = data;
    state.status = {};
  },
  componentCertificationsSuccess(state: any, data: any[]) {
    state.componentCertifications = data;
    state.status = {};
  },
  materialCertificationsSuccess(state: any, data: any[]) {
    state.materialCertifications = data;
    state.status = {};
  },
  materialColorsSuccess(state: any, data: any[]) {
    state.materialColors = data;
    state.status = {};
  },
  materialConstructionsSuccess(state: any, data: any[]) {
    state.materialConstructions = data;
    state.status = {};
  },
  materialDyeMethodsSuccess(state: any, data: any[]) {
    state.materialDyeMethods = data;
    state.status = {};
  },
  materialPrintMethodsSuccess(state: any, data: any[]) {
    state.materialPrintMethods = data;
    state.status = {};
  },
  materialPatternsSuccess(state: any, data: any[]) {
    state.materialPatterns = data;
    state.status = {};
  },
  materialFinishingsSuccess(state: any, data: any[]) {
    state.materialFinishings = data;
    state.status = {};
  },
  productFinishingsSuccess(state: any, data: any[]) {
    state.productFinishings = data;
    state.status = {};
  },
  productCategoriesSuccess(state: any, data: any[]) {
    state.productCategories = data;
    state.status = {};
  },
  productTypesSuccess(state: any, data: any[]) {
    state.productTypes = data;
    state.status = {};
  },
  productRecyclingSuccess(state: any, data: any[]) {
    state.productRecycling = data;
    state.status = {};
  },
  stepTypesSuccess(state: any, data: any[]) {
    state.stepTypes = data;
    state.status = {};
  },
  locationsSuccess(state: any, data: any[]) {
    state.locations = data;
    state.status = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
