import { componentService } from "../../services";
import { Component } from "../../types";
import router from "@/config/PageRouter";

const getDefaultState = () => {
  return {
    component: {},
    components: [],
    status: {},
    error: null,
  };
};

// initial state
const state = getDefaultState();

// actions
const actions = {
  list({ commit }: any) {
    commit("waitResponse");
    componentService.list().then(
      (res: any) => {
        commit("listSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  find({ commit }: any, id: any) {
    componentService.find(id).then(
      (res: any) => {
        commit("findSuccess", res.data);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  findPublic({ commit }: any, id: any) {
    componentService.findPublic(id).then(
      (res: any) => {
        commit("findSuccess", res.data);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  create({ commit }: any, data: any) {
    commit("waitResponse");
    componentService.create(data).then(
      (res: any) => {
        commit("createSuccess", res);
        router.push("/components");
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  update(
    { commit }: any,
    {
      id,
      data,
    }: {
      id: any;
      data: any;
    }
  ) {
    commit("waitResponse");
    componentService.update(id, data).then(
      (res: any) => {
        commit("updateSuccess", res);
        if (res.id) router.push("/components/" + res.id);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  delete({ commit }: any, id: any) {
    componentService.destroy(id).then(
      (res: any) => {
        if (res.success) {
          commit("deleteSuccess", res.data);
        } else {
          commit("failure", res.errors);
        }
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
};

// getters
const getters = {
  getComponents(state: any) {
    return state.components;
  },
  getComponent(state: any) {
    return state.component;
  },
};

// mutations
const mutations = {
  resetState(state: any) {
    Object.assign(state, getDefaultState());
  },
  waitResponse(state: any) {
    state.status = { isWaiting: true };
  },
  failure(state: any, error: any) {
    state.error = error;
    state.status = {};
  },
  listSuccess(state: any, components: Component[]) {
    state.components = components;
    state.status = {};
  },
  findSuccess(state: any, component: Component) {
    state.component = component;
    state.status = {};
  },
  createSuccess(state: any, component: Component) {
    state.components.push(component);
    state.component = component;
    state.status = {};
  },
  updateSuccess(state: any, component: Component) {
    let index = state.components.findIndex(
      (p: Component) => p.id == component.id
    );
    if (index != -1) {
      state.components.splice(index, 1, component);
    }
    state.status = {};
  },
  deleteSuccess(state: any, component: Component) {
    let index = state.components.findIndex(
      (p: Component) => p.id == component.id
    );
    if (index != -1) {
      state.components.splice(index, 1);
    }
    state.status = {};
  },
  resetError(state: any) {
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
