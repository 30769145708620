import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../pages/Home.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../pages/Login.vue"),
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("../pages/Signup.vue"),
    },
    {
      path: "/forgot",
      name: "forgot",
      component: () => import("../pages/Forgot.vue"),
    },
    {
      path: "/fibers",
      name: "fibers",
      component: () => import("../pages/fiber/Fibers.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/fibers/new",
      name: "fiberCreate",
      component: () => import("../pages/fiber/FiberCreate.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/fibers/public/:id",
      name: "fiberPublic",
      component: () => import("../pages/fiber/FiberPublic.vue"),
    },
    {
      path: "/fibers/:id",
      name: "fiber",
      component: () => import("../pages/fiber/Fiber.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/fibers/:id/edit",
      name: "fiberEdit",
      component: () => import("../pages/fiber/FiberEdit.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/components",
      name: "components",
      component: () => import("../pages/component/Components.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/components/new",
      name: "componentCreate",
      component: () => import("../pages/component/ComponentCreate.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/components/public/:id",
      name: "componentPublic",
      component: () => import("../pages/component/ComponentPublic.vue"),
    },
    {
      path: "/components/:id",
      name: "component",
      component: () => import("../pages/component/Component.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/components/:id/edit",
      name: "componentEdit",
      component: () => import("../pages/component/ComponentEdit.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/materials",
      name: "materials",
      component: () => import("../pages/material/Materials.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/materials/new",
      name: "materialCreate",
      component: () => import("../pages/material/MaterialCreate.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/materials/public/:id",
      name: "materialPublic",
      component: () => import("../pages/material/MaterialPublic.vue"),
    },
    {
      path: "/materials/:id",
      name: "material",
      component: () => import("../pages/material/Material.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/materials/:id/edit",
      name: "materialEdit",
      component: () => import("../pages/material/MaterialEdit.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/products",
      name: "products",
      component: () => import("../pages/product/Products.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/products/new",
      name: "productCreate",
      component: () => import("../pages/product/ProductCreate.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/products/public/:id",
      name: "productPublic",
      component: () => import("../pages/product/ProductPublic.vue"),
    },
    {
      path: "/products/:id",
      name: "product",
      component: () => import("../pages/product/Product.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/products/:id/edit",
      name: "productEdit",
      component: () => import("../pages/product/ProductEdit.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/assemblies",
      name: "assemblies",
      component: () => import("../pages/assembly/Assemblies.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/assemblies/new",
      name: "assemblyCreate",
      component: () => import("../pages/assembly/AssemblyCreate.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/assemblies/public/:id",
      name: "assemblyPublic",
      component: () => import("../pages/assembly/AssemblyPublic.vue"),
    },
    {
      path: "/assemblies/:id",
      name: "assembly",
      component: () => import("../pages/assembly/Assembly.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/assemblies/:id/edit",
      name: "assemblyEdit",
      component: () => import("../pages/assembly/AssemblyEdit.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("../pages/company/Companies.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/companies/new",
      name: "companyCreate",
      component: () => import("../pages/company/CompanyCreate.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/companies/:id",
      name: "company",
      component: () => import("../pages/company/Company.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/companies/:id/edit",
      name: "companyEdit",
      component: () => import("../pages/company/CompanyEdit.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("../pages/Profile.vue"),
      meta: { authRequired: true },
    },
    {
      path: "/:catchAll(.*)",
      component: () => import("../pages/Home.vue"),
    },
  ],
});

router.beforeResolve((to, from, next) => {
  const loggedIn =
    Cookies.get("finix-token") && Cookies.get("finix-user") ? true : false;

  if (to.meta.authRequired && !loggedIn) return next("/login");

  next();
});

export default router;
