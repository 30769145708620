import { authHeaders, handleResponse } from "../helpers";

export const resourceService = {
  listRoles,
  listComponentFibers,
  listComponentTypes,
  listComponentCertifications,
  listMaterialCertifications,
  listMaterialColors,
  listMaterialConstructions,
  listMaterialDyeMethods,
  listMaterialPrintMethods,
  listMaterialPatterns,
  listMaterialFinishings,
  listProductFinishings,
  listProductCategories,
  listProductTypes,
  listProductRecycling,
  listStepTypes,
  listStepTypesByCategoryName,
  listLocations,
};

function listRoles() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(process.env.VUE_APP_API_URL + `/resource/roles`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listComponentFibers() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/component/fibers`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listComponentTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/component/types`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listComponentCertifications() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/component/certifications`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listMaterialCertifications() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/material/certifications`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listMaterialColors() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/material/colors`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listMaterialConstructions() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/material/constructions`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listMaterialDyeMethods() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/material/dyemethods`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listMaterialPrintMethods() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/material/printmethods`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listMaterialPatterns() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/material/patterns`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listMaterialFinishings() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/material/finishings`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listProductFinishings() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/product/finishings`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listProductCategories() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/product/categories`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listProductTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/product/types`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listProductRecycling() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/product/recycling`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listStepTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/steptypes`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listStepTypesByCategoryName(category: string) {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/steptypes/${category}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function listLocations() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/resource/locations`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
