import { authHeaders, handleResponse } from "../helpers";

export const userService = {
  profileUpdate,
  changePassword,
  invite,
};

function profileUpdate(userData: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeaders(),
    body: JSON.stringify(userData),
  };
  return fetch(process.env.VUE_APP_API_URL + "/user/profile", requestOptions)
    .then(handleResponse)
    .then((response: any) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function changePassword(id: any, passwordData: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeaders(),
    body: JSON.stringify(passwordData),
  };
  return fetch(
    process.env.VUE_APP_API_URL + "/user/change/" + id,
    requestOptions
  )
    .then(handleResponse)
    .then((response: any) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
function invite(data: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeaders(),
    body: JSON.stringify(data),
  };
  return fetch(process.env.VUE_APP_API_URL + "/user/invite", requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response;
    });
}
