<template>
  <div id="container" class="flex">
    <div id="helper" class="hidden md:flex" />
    <div id="content">
      <navbar v-if="!noTopbarRoutes.includes(route.name)" />
      <router-view class="px-1 py-3 lg:px-4" />
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { usePrimeVue } from "primevue/config";
import { useI18n } from "vue-i18n";
import Navbar from "./components/navbar/Navbar.vue";
export default {
  name: "App",
  components: {
    Navbar,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const route = useRoute();

    const noTopbarRoutes = [
      "login",
      "signup",
      "forgot",
      "fiberPublic",
      "componentPublic",
      "materialPublic",
      "assemblyPublic",
      "productPublic",
    ];

    const primevue = usePrimeVue();
    primevue.config.locale.dateFormat = t("primevue.date_format");
    primevue.config.locale.dayNames = [
      t("days.sunday"),
      t("days.monday"),
      t("days.tuesday"),
      t("days.wednesday"),
      t("days.thursday"),
      t("days.friday"),
      t("days.saturday"),
    ];
    primevue.config.locale.dayNamesMin = [
      t("days.min.su"),
      t("days.min.mo"),
      t("days.min.tu"),
      t("days.min.we"),
      t("days.min.th"),
      t("days.min.fr"),
      t("days.min.sa"),
    ];
    primevue.config.locale.dayNamesShort = [
      t("days.short.sun"),
      t("days.short.mon"),
      t("days.short.tue"),
      t("days.short.wed"),
      t("days.short.thu"),
      t("days.short.fri"),
      t("days.short.sat"),
    ];
    primevue.config.locale.emptyMessage = t("primevue.empty_message");
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.medium = t("primevue.medium");
    primevue.config.locale.monthNames = [
      t("months.january"),
      t("months.february"),
      t("months.march"),
      t("months.april"),
      t("months.may"),
      t("months.june"),
      t("months.july"),
      t("months.august"),
      t("months.september"),
      t("months.october"),
      t("months.november"),
      t("months.december"),
    ];
    primevue.config.locale.monthNamesShort = [
      t("months.short.jan"),
      t("months.short.feb"),
      t("months.short.mar"),
      t("months.short.apr"),
      t("months.short.may"),
      t("months.short.jun"),
      t("months.short.jul"),
      t("months.short.aug"),
      t("months.short.sep"),
      t("months.short.oct"),
      t("months.short.nov"),
      t("months.short.dec"),
    ];
    primevue.config.locale.passwordPrompt = t("primevue.password_prompt");
    primevue.config.locale.strong = t("primevue.strong");
    primevue.config.locale.today = t("days.today");
    primevue.config.locale.weak = t("primevue.weak");
    primevue.config.locale.weekHeader = t("primevue.week_header");

    return {
      route,
      noTopbarRoutes,
    };
  },
};
</script>

<style>
html,
body,
#app,
#container {
  min-height: 100vh;
}

#app {
  background-color: var(--bg-color);
}

#helper {
  height: 100vh;
}

#content {
  flex-grow: 1;
  width: 100%;
}
</style>
