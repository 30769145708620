import { createI18n } from "vue-i18n";

function loadLocaleTexts() {
  const locales = require.context(
    "./languages",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const texts = {} as any;
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      texts[locale] = locales(key);
    }
  });
  return texts;
}

export default createI18n({
  //locale: navigator.language.substring(0, 2) || "en",
  locale: "en",
  fallbackLocale: process.env.I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleTexts(),
});
