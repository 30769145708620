import { authHeaders, handleResponse } from "../helpers";

export const materialService = {
  list,
  find,
  findPublic,
  create,
  update,
  destroy,
};

function list() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(process.env.VUE_APP_API_URL + `/material/`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function find(id: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(process.env.VUE_APP_API_URL + `/material/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response;
    });
}

function findPublic(id: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(
    process.env.VUE_APP_API_URL + `/material/public/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response;
    });
}

function create(data: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeaders(),
    body: JSON.stringify(data),
  };
  return fetch(process.env.VUE_APP_API_URL + "/material", requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function update(id: any, data: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeaders(),
    body: JSON.stringify(data),
  };
  return fetch(process.env.VUE_APP_API_URL + `/material/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function destroy(id: any) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeaders(),
  };
  return fetch(process.env.VUE_APP_API_URL + `/material/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
