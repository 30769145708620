import { handleResponse } from "../helpers";
import Cookies from "js-cookie";

export const authService = {
  signup,
  login,
  logout,
  forgot,
  resetPassword,
};

function signup(userData: any) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
  };
  return fetch(process.env.VUE_APP_API_URL + "/auth/signup", requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function login(username: string, password: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };
  return fetch(process.env.VUE_APP_API_URL + "/auth/signin", requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      if (response.data.token) {
        Cookies.set("finix-token", JSON.stringify(response.data.token), {
          secure: true,
          sameSite: "strict",
        });
      }
      return response.data.user;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function logout() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(process.env.VUE_APP_API_URL + "/auth/signout", requestOptions)
    .then(handleResponse)
    .then((response) => {
      Cookies.remove("finix-token");
      return response;
    });
}

function forgot(username: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(username),
  };
  return fetch(process.env.VUE_APP_API_URL + "/auth/forgot", requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function resetPassword(token: string, password: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token,
      password,
    }),
  };
  return fetch(process.env.VUE_APP_API_URL + "/auth/reset", requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
