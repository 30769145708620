
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t } = useI18n({ useScope: "global" });

    const store = useStore();

    const route = useRoute();

    const logout = () => {
      store.dispatch("user/logout");
    };

    const currentUser = computed(() => store.getters["user/getCurrentUser"]);

    // update username label on profile username change
    store.watch(
      (state) => state.user.currentUser,
      (user) => {
        if (user && user.username) {
          let navMenuIndex = navItems.value.length - 1;
          navItems.value[navMenuIndex].label = user.username;
        }
      }
    );

    // watch route & set active class
    watch(
      () => route.path,
      (path) => {
        for (var item of navItems.value as any) {
          if (!item.to && item.items) {
            for (var subItem of item.items as any) {
              if (subItem.to && path.includes(subItem.to)) {
                subItem.class = "router-link-active";
              } else subItem.class = null;
            }
          } else {
            if (item.to === "/") {
              if (item.to === path) {
                item.class = "router-link-active";
              } else item.class = null;
            } else if (item.to && path.includes(item.to)) {
              item.class = "router-link-active";
            } else item.class = null;
          }
        }
      }
    );

    const navItems = ref([
      {
        label: t("nav.home"),
        icon: "pi pi-home",
        to: "/",
      },
      {
        label: t("nav.fibers"),
        icon: "pi pi-list",
        to: "/fibers",
      },
      {
        label: t("nav.components"),
        icon: "pi pi-sitemap",
        to: "/components",
      },
      {
        label: t("nav.materials"),
        icon: "pi pi-table",
        to: "/materials",
      },
      {
        label: t("nav.assemblies"),
        icon: "pi pi-clone",
        to: "/assemblies",
      },
      {
        label: t("nav.products"),
        icon: "pi pi-tags",
        to: "/products",
      },
      {
        label:
          currentUser && currentUser.value && currentUser.value.username
            ? currentUser.value.username
            : t("nav.user"),
        icon: "pi pi-user",
        items: [
          /*
          {
            label: t("nav.gdpr"),
            icon: "pi pi-sitemap",
            to: "/gdpr",
          },
          {
            label: t("nav.manual"),
            icon: "pi pi-book",
            to: "/manual",
          },
          */
          {
            label: t("nav.companies"),
            icon: "pi pi-briefcase",
            to: "/companies",
          },
          {
            separator: true,
          },
          {
            label: t("nav.profile"),
            icon: "pi pi-user",
            to: "/profile",
          },
          {
            separator: true,
          },
          {
            label: t("nav.logout"),
            icon: "pi pi-sign-out",
            command: logout,
          },
        ],
      },
    ]);

    return {
      t,
      route,
      navItems,
      logout,
    };
  },
};
