import { authHeaders, handleResponse } from "../helpers";

export const companyService = {
  list,
  find,
  create,
  update,
  destroy,
  updateCompanyUser,
};

function list() {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(process.env.VUE_APP_API_URL + `/company/`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function find(id: any) {
  const requestOptions = {
    method: "GET",
    headers: authHeaders(),
  };
  return fetch(process.env.VUE_APP_API_URL + `/company/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response;
    });
}

function create(data: any) {
  const requestOptions = {
    method: "POST",
    headers: authHeaders(),
    body: JSON.stringify(data),
  };
  return fetch(process.env.VUE_APP_API_URL + "/company", requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function update(id: any, data: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeaders(),
    body: JSON.stringify(data),
  };
  return fetch(process.env.VUE_APP_API_URL + `/company/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function destroy(id: any) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeaders(),
  };
  return fetch(process.env.VUE_APP_API_URL + `/company/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateCompanyUser(data: any) {
  const requestOptions = {
    method: "PUT",
    headers: authHeaders(),
    body: JSON.stringify(data),
  };
  return fetch(
    process.env.VUE_APP_API_URL + "/company/updateuser",
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      if (!response.success) {
        throw response.errors;
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
