import { User, Company } from "../types";

const isCompanyAdmin = (company: Company, user: User) => {
  if (user && company && company.users && company.users.length) {
    let cUser = company.users.find((u) => u.userId === user.id);
    if (cUser && cUser.roleId && cUser.roleId === 1) return true;
    return false;
  }
  return false;
};

export { isCompanyAdmin };
