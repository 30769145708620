//import store from "../store";
import Cookies from "js-cookie";

export function authHeaders() {
  /*
  const storeState = store.state as any;
  const roles = storeState.user.user.roles;
  let role = "basic";
  if (roles) {
    roles.forEach((r: any) => {
      if (r.name == "companyAdmin") role = r.name;
    });
  }
  */
  const token = JSON.parse(Cookies.get("finix-token") || "{}");
  let headers: any = {};
  if (token.length > 2) {
    headers = {
      "Content-Type": "application/json",
      Authorization: token,
      //role: role,
    };
  }
  return headers;
}
