import i18n from "../i18n";
const { locale } = i18n.global;

const isFormChanged = (
  prop: any,
  inputs: any,
  steps: any = null,
  itemType: string = "",
  items: any = null
) => {
  var isChanged = false;
  if (prop) {
    var keys = Object.keys(inputs);
    for (var k in keys) {
      if (isChanged) continue;
      var original = null,
        changed = inputs[keys[k]];
      if (typeof prop[keys[k]] === "object" && prop[keys[k]] != null) {
        if (typeof prop[keys[k]]?.length != "undefined") {
          original = prop[keys[k]].map((item: any) => item.id);
        } else {
          original = prop[keys[k]].id;
        }
      } else {
        original = prop[keys[k]];
      }

      if (
        typeof original === "object" &&
        original != null &&
        original?.length != "undefined"
      ) {
        if (original.length != changed.length) {
          isChanged = true;
          continue;
        }
        for (var i = 0, l = original.length; i < l; i++) {
          if (original[i] instanceof Array && changed[i] instanceof Array) {
            if (!original[i].equals(changed[i])) {
              isChanged = true;
            }
          } else if (original[i] != changed[i]) {
            isChanged = true;
          }
        }
      } else if (changed != original) isChanged = true;
    }
    // steps
    if (steps && steps.length) {
      if (
        !prop.steps ||
        (prop.steps && !prop.steps.length) ||
        prop.steps.length != steps.length
      )
        isChanged = true;
      if (!isChanged) {
        for (var s in prop.steps) {
          if (isChanged) continue;
          var sKeys = Object.keys(prop.steps[s]),
            propStep = prop.steps[s],
            inputStep = steps[s];
          for (var sk of sKeys) {
            if (isChanged) continue;
            if (typeof propStep[sk] === "object" && propStep[sk] != null) {
              if (propStep[sk].id && inputStep[sk] && inputStep[sk].id) {
                if (propStep[sk].id != inputStep[sk].id) isChanged = true;
              }
            } else if (propStep[sk] != inputStep[sk]) isChanged = true;
          }
        }
      }
    }
    // items
    if (itemType && items && items.length) {
      var itemComponents = prop[itemType];
      if (
        !itemComponents ||
        (itemComponents && !itemComponents.length) ||
        itemComponents.length != items.length
      )
        isChanged = true;
      if (itemComponents && itemComponents.length) {
        for (var ic in itemComponents) {
          if (isChanged) continue;
          var icKeys = Object.keys(itemComponents[ic]),
            propComp = itemComponents[ic],
            inputItem = items[ic];
          for (var ick of icKeys) {
            if (isChanged) continue;
            if (typeof propComp[ick] === "object" && propComp[ick] != null) {
              if (propComp[ick].id && inputItem[ick] && inputItem[ick].id) {
                if (propComp[ick].id != inputItem[ick].id) isChanged = true;
              }
            } else if (propComp[ick] != inputItem[ick]) isChanged = true;
          }
        }
      }
    }
  } else {
    for (var input in inputs) {
      if (inputs[input]) {
        if (inputs[input].length != 0) isChanged = true;
      } else {
        if (inputs[input] != null && inputs[input] != "") isChanged = true;
      }
    }
    if (steps && steps.length) isChanged = true;
    if (items && items.length) isChanged = true;
  }
  return isChanged;
};

const parseResourceName = (resourceName: any) => {
  if (resourceName) {
    const name =
      resourceName.find((language: any) => language.code == locale) ||
      resourceName.find((language: any) => language.code == "en");
    return name.value;
  } else return null;
};

const parseResourceNames = (names: any) => {
  var arr = names.map((item: any) => parseResourceName(item.name));
  return arr.join(", ");
};

const parseSelectionNamesFromIds = (ids: any, resources: any) => {
  var names = [] as any;
  ids.forEach((id: number) => {
    var res = resources.find((resource: any) => resource.id === id);
    if (res) names.push(parseResourceName(res.name));
  });
  return names.join(", ");
};

export {
  isFormChanged,
  parseResourceName,
  parseResourceNames,
  parseSelectionNamesFromIds,
};
