import { authService, userService } from "../../services";
import router from "../../config/PageRouter";
import Cookies from "js-cookie";
import { User, UserState } from "../../types";

const getDefaultState = () => {
  return {
    currentUser: {},
    user: {},
    users: [],
    status: {},
    error: null,
    inviteSuccess: false,
  };
};

// initial state
const state = getDefaultState();

// actions
const actions = {
  signup({ commit }: any, userData: any) {
    commit("waitResponse");
    authService.signup(userData).then(
      (user) => {
        commit("signupSuccess", user);
        router.push("/login");
      },
      (error) => {
        var errorMsg = null;
        if (error && error.length) {
          error.forEach((e: any) => {
            if (e.constraints && e.constraints.isLength)
              errorMsg = "password_length";
          });
        } else if (error.message) {
          errorMsg = error.message;
        }
        commit("signupFailure", errorMsg ? errorMsg : error);
      }
    );
  },
  login(
    { commit }: any,
    { username, password }: { username: string; password: string }
  ) {
    commit("loginRequest", { username });
    authService.login(username, password).then(
      (user) => {
        commit("loginSuccess", user);
        router.push("/");
      },
      (error) => {
        commit("loginFailure", error.message ? error.message : error);
      }
    );
  },
  logout({ commit, dispatch }: any) {
    authService.logout();
    router.push("/login");
    commit("logout");
    dispatch("clearAll", null, { root: true });
  },
  forgot({ commit }: any, username: string) {
    commit("waitResponse");
    authService.forgot(username).then(
      (user) => {
        commit("forgotSuccess", user);
      },
      (error) => {
        commit("forgotFailure", error);
      }
    );
  },
  resetPassword({ commit }: any, { token, password }: any) {
    commit("waitResponse");
    authService.resetPassword(token, password).then(
      (user) => {
        commit("resetPasswordSuccess", user);
      },
      (error) => {
        commit("resetPasswordFailure", error);
      }
    );
  },
  profileUpdate({ commit }: any, userData: any) {
    commit("waitResponse");
    userService.profileUpdate(userData).then(
      (user) => {
        commit("profileUpdateSuccess", user);
      },
      (error) => {
        commit("profileFailure", error);
      }
    );
  },
  invite({ commit }: any, data: any) {
    commit("waitResponse");
    userService.invite(data).then(
      (res) => {
        commit("inviteSuccess", res);
      },
      (error) => {
        commit("failure", error);
      }
    );
  },
  resetUserError({ commit }: any) {
    commit("resetError");
  },
};

// getters
const getters = {
  user: (state: UserState) => state.user,
  getUsers(state: any) {
    return state.users;
  },
  getUser(state: any) {
    return state.user;
  },
  getCurrentUser(state: any) {
    return state.currentUser;
  },
};

// mutations
const mutations = {
  resetState(state: UserState) {
    Object.assign(state, getDefaultState());
  },
  initialiseStore(state: UserState) {
    if (Cookies.get("finix-token")) {
      state.status = { loggedIn: true };
    }
    if (Cookies.get("finix-user")) {
      state.currentUser = JSON.parse(Cookies.get("finix-user")!);
    }
  },
  waitResponse(state: UserState) {
    state.status = { isWaiting: true };
  },
  setEmail(state: UserState, email: string) {
    localStorage.setItem("email", email);
  },
  activationSuccess(state: UserState) {
    state.status = { message: "activation" };
    if (localStorage.getItem("email")) {
      localStorage.removeItem("email");
    }
  },
  signupSuccess(state: UserState) {
    state.status = { signedUp: true };
    state.status = {};
    state.error = null;
  },
  signupFailure(state: UserState, error: any) {
    state.status = {};
    state.error = error;
  },
  loginRequest(state: UserState, user: User) {
    state.status = { loggingIn: true };
    state.currentUser = user;
  },
  loginSuccess(state: UserState, user: User) {
    Cookies.set("finix-user", JSON.stringify(user), {
      secure: true,
      sameSite: "strict",
    });
    state.status = { loggedIn: true };
    state.currentUser = user;
  },
  loginFailure(state: UserState, error: any) {
    state.status = {};
    state.currentUser = {};
    state.error = error;
  },
  logout() {
    Cookies.remove("finix-user");
  },
  forgotSuccess(state: UserState) {
    state.status = { message: "password_request_sent" };
  },
  forgotFailure(state: UserState, error: any) {
    state.status = {};
    state.error = error;
  },
  resetPasswordSuccess(state: UserState) {
    state.status = { message: "password_reset" };
  },
  resetPasswordFailure(state: UserState, error: any) {
    state.status = {};
    state.error = error;
  },
  profileUpdateSuccess(state: UserState, user: User) {
    Cookies.set("finix-user", JSON.stringify(user), {
      secure: true,
      sameSite: "strict",
    });
    state.currentUser = user;
    state.status = {};
  },
  updateFailure(state: UserState, error: any) {
    state.error = error;
    state.status = {};
  },
  passwordChangeSuccess(state: UserState) {
    state.status = {};
  },
  passwordChangeFailure(state: UserState, error: any) {
    state.error = error;
    state.status = {};
  },
  listSuccess(state: any, users: User[]) {
    state.users = users;
    state.status = {};
  },
  findSuccess(state: any, user: User) {
    state.user = user;
    state.status = {};
  },
  findCurrentUserSuccess(state: any, user: User) {
    state.currentUser = user;
    state.status = {};
  },
  profileFailure(state: UserState, error: any) {
    state.error = error;
    state.status = {};
  },
  failure(state: UserState, error: any) {
    state.error = error;
    state.status = {};
  },
  inviteSuccess(state: UserState) {
    state.inviteSuccess = true;
    state.status = {};
  },
  resetInviteSuccess(state: UserState) {
    state.inviteSuccess = false;
    state.status = {};
  },
  resetError(state: UserState) {
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
