import { createStore } from "vuex";
import resource from "./modules/resource.store";
import user from "./modules/user.store";
import company from "./modules/company.store";
import fiber from "./modules/fiber.store";
import component from "./modules/component.store";
import material from "./modules/material.store";
import assembly from "./modules/assembly.store";
import product from "./modules/product.store";

const store = createStore({
  modules: {
    resource,
    user,
    company,
    fiber,
    component,
    material,
    assembly,
    product,
  },
  actions: {
    clearAll({ commit }) {
      commit("resource/resetState");
      commit("user/resetState");
      commit("fiber/resetState");
      commit("company/resetState");
      commit("component/resetState");
      commit("material/resetState");
      commit("assembly/resetState");
      commit("product/resetState");
    },
  },
});

export default store;
