const formatDate = (date: string) => {
  if (date) {
    return new Date(date).toLocaleString("fi-FI", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  }
  return date;
};

const formatTime = (date: any) => {
  if (date) {
    const d = new Date(date);
    const h = (d.getHours() < 10 ? "0" : "") + d.getHours();
    const m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return h + ":" + m;
  }
  return date;
};

const formatDateTime = (date: string) => {
  if (date) {
    date = date.replace(" ", "T");
    const dateString = new Date(date.split(" ")[0]).toLocaleString("fi-FI", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    const d = new Date(date);
    const h = (d.getHours() < 10 ? "0" : "") + d.getHours();
    const m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return dateString + ", " + h + ":" + m;
  }
  return date;
};

export { formatDate, formatTime, formatDateTime };
