import { fiberService } from "../../services";
import { Fiber } from "../../types";
import router from "@/config/PageRouter";

const getDefaultState = () => {
  return {
    fiber: {},
    fibers: [],
    status: {},
    error: null,
  };
};

// initial state
const state = getDefaultState();

// actions
const actions = {
  list({ commit }: any) {
    commit("waitResponse");
    fiberService.list().then(
      (res: any) => {
        commit("listSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  find({ commit }: any, id: any) {
    fiberService.find(id).then(
      (res: any) => {
        commit("findSuccess", res.data);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  findPublic({ commit }: any, id: any) {
    fiberService.findPublic(id).then(
      (res: any) => {
        commit("findSuccess", res.data);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  create({ commit }: any, data: any) {
    commit("waitResponse");
    fiberService.create(data).then(
      (res: any) => {
        commit("createSuccess", res);
        router.push("/fibers");
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  update(
    { commit }: any,
    {
      id,
      data,
    }: {
      id: any;
      data: any;
    }
  ) {
    commit("waitResponse");
    fiberService.update(id, data).then(
      (res: any) => {
        commit("updateSuccess", res);
        if (res.id) router.push("/fibers/" + res.id);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  delete({ commit }: any, id: any) {
    fiberService.destroy(id).then(
      (res: any) => {
        if (res.success) {
          commit("deleteSuccess", res.data);
        } else {
          commit("failure", res.errors);
        }
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
};

// getters
const getters = {
  getFibers(state: any) {
    return state.fibers;
  },
  getFiber(state: any) {
    return state.fiber;
  },
};

// mutations
const mutations = {
  resetState(state: any) {
    Object.assign(state, getDefaultState());
  },
  waitResponse(state: any) {
    state.status = { isWaiting: true };
  },
  failure(state: any, error: any) {
    state.error = error;
    state.status = {};
  },
  listSuccess(state: any, fibers: Fiber[]) {
    state.fibers = fibers;
    state.status = {};
  },
  findSuccess(state: any, fiber: Fiber) {
    state.fiber = fiber;
    state.status = {};
  },
  createSuccess(state: any, fiber: Fiber) {
    state.fibers.push(fiber);
    state.fiber = fiber;
    state.status = {};
  },
  updateSuccess(state: any, fiber: Fiber) {
    let index = state.fibers.findIndex((p: Fiber) => p.id == fiber.id);
    if (index != -1) {
      state.fibers.splice(index, 1, fiber);
    }
    state.status = {};
  },
  deleteSuccess(state: any, fiber: Fiber) {
    let index = state.fibers.findIndex((p: Fiber) => p.id == fiber.id);
    if (index != -1) {
      state.fibers.splice(index, 1);
    }
    state.status = {};
  },
  resetError(state: any) {
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
