import { companyService } from "../../services";
import { Company } from "../../types";
import router from "@/config/PageRouter";

const getDefaultState = () => {
  return {
    company: {},
    companies: [],
    status: {},
    error: null,
  };
};

// initial state
const state = getDefaultState();

// actions
const actions = {
  list({ commit }: any) {
    commit("waitResponse");
    companyService.list().then(
      (res: any) => {
        commit("listSuccess", res);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  find({ commit }: any, id: any) {
    companyService.find(id).then(
      (res: any) => {
        commit("findSuccess", res.data);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  create({ commit }: any, data: any) {
    commit("waitResponse");
    companyService.create(data).then(
      (res: any) => {
        commit("createSuccess", res);
        router.push("/companies");
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  update(
    { commit }: any,
    {
      id,
      data,
    }: {
      id: any;
      data: any;
    }
  ) {
    commit("waitResponse");
    companyService.update(id, data).then(
      (res: any) => {
        commit("updateSuccess", res);
        router.push("/companies/" + res.id);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
  delete({ commit }: any, id: any) {
    companyService.destroy(id).then(
      (res: any) => {
        if (res.success) {
          commit("deleteSuccess", res.data);
        } else {
          commit("failure", res.errors);
        }
      },
      (error: any) => {
        commit("companyFailure", error);
      }
    );
  },
  updateCompanyUser(
    { commit }: any,
    {
      id,
      data,
    }: {
      id: any;
      data: any;
    }
  ) {
    commit("waitResponse");
    companyService.updateCompanyUser(data).then(
      (res: any) => {
        commit("updateSuccess", res);
        //router.push("/companies/" + res.id);
      },
      (error: any) => {
        commit("failure", error);
      }
    );
  },
};

// getters
const getters = {
  getCompanies(state: any) {
    return state.companies;
  },
  getCompany(state: any) {
    return state.company;
  },
};

// mutations
const mutations = {
  resetState(state: any) {
    Object.assign(state, getDefaultState());
  },
  waitResponse(state: any) {
    state.status = { isWaiting: true };
  },
  failure(state: any, error: any) {
    state.error = error;
    state.status = {};
  },
  listSuccess(state: any, companies: Company[]) {
    state.companies = companies;
    state.status = {};
  },
  findSuccess(state: any, company: Company) {
    state.company = company;
  },
  createSuccess(state: any, company: Company) {
    state.companies.push(company);
    state.company = company;
    state.status = {};
  },
  updateSuccess(state: any, company: Company) {
    let index = state.companies.findIndex((p: Company) => p.id == company.id);
    if (index != -1) {
      state.companies.splice(index, 1, company);
    }
    state.status = {};
  },
  deleteSuccess(state: any, company: Company) {
    let index = state.companies.findIndex((p: Company) => p.id == company.id);
    if (index != -1) {
      state.companies.splice(index, 1);
    }
    state.status = {};
  },
  resetError(state: any) {
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
